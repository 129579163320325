<template>
  <div class="">

    <div>
      <div class="form-row">
        <sgv-input-text
          class="col-12"
          v-model="search"
          label="ค้นหา">
        </sgv-input-text>
      </div>

      <ul class="my-0">
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addDoc(item)">
          </fa>&nbsp;
          {{item.code}}: {{item.name}}
        </li>
      </ul>
    </div>

    เอกสาร:
    <ul>
      <li v-for="doc in docs" :key="doc.id">
        <fa
          icon="trash"
          class="text-danger pointer"
          @click="destroyDoc(doc.id)">
        </fa>&nbsp;
        {{doc.code}}: {{doc.name}}
      </li>
    </ul>
  </div>
</template>

<script>
import {
  LIST_DROPDOWN_DOC,
  CREATE_DOC,
  DESTROY_DOC
} from './graph'
import debounce from 'lodash/debounce'

export default {
  props: {
    bankStatementId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    bankStatementType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  computed: {
    docs () {
      return this.formData.docs.filter(v => {
        const name = v.name || ''
        const isCodeFound = v.code.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        const isNameFound = name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        return isCodeFound || isNameFound
      })
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_DROPDOWN_DOC(this.templateType),
        variables: {
          bankStatementType: this.bankStatementType,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch(() => this.items = [])
    }, 150),
    addDoc (doc) {
      this.$apollo.mutate({
        mutation: CREATE_DOC(this.templateType),
        variables: {
          bankStatementType: this.bankStatementType,
          bankStatementId: this.bankStatementId,
          docId: doc.id
        }
      })
      .then(() => {
        this.addDocs(doc)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyDoc (docId) {
      this.$apollo.mutate({
        mutation: DESTROY_DOC(this.templateType),
        variables: {
          bankStatementType: this.bankStatementType,
          bankStatementId: this.bankStatementId,
          docId
        }
      })
      .then(() => {
        this.removeDocs(docId)
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitDocs (arr) {
      this.$emit('docs', arr)
    },
    addDocs (doc) {
      const index = this.formData.docs.findIndex(v => v.id === doc.id)
      if (index === -1) this.formData.docs.unshift(doc)
    },
    removeDocs (docId) {
      const index = this.formData.docs.findIndex(v => v.id === docId)
      this.formData.docs.splice(index, 1)
    },
  },
  watch: {
    search (value) {
      if (value) {
        this.getList({
          limit: 5,
          params: {
            search: value
          },
          order: 'code'
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
