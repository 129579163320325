<template>
  <button
    @click="download"
    class="btn btn-success">
    <fa icon="download"></fa>
    Excel
  </button>
</template>

<script>
import round from 'lodash/round'
import xlsx from 'json-as-xlsx'

export default {
  props: {
    bankStatementType: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      sheetname: '000000',
      columns: [
        {label: 'วันที่', value: 'transactionAt'},
        {label: 'เลขที่ใบเสร็จรับเงิน', value: 'docCode'},
        {label: 'เจ้าหนี้', value: 'creditContactName'},
        {label: 'ถอนเงิน', value: 'credit'},
        {label: 'รหัส', value: 'debitContactCode'},
        {label: 'ลูกหนี้', value: 'debitContactName'},
        {label: 'ฝากเงิน', value: 'debit'},
        {label: 'ยอดเงินคงเหลือ', value: 'balance'},
      ]
    }
  },
  computed: {
    filename () {
      return `statement_${this.bankStatementType}_${this.startDate}_${this.endDate}`
    },
    rows () {
      const content = this.items.flatMap(v => {
        return {
          transactionAt : this.$date.format(v.transactionAt).displayThai,
          docCode : v.docs.map(d => d.code).join(', '),
          creditContactName : v.credit ? v.docs.map(d => d.contact.name).join(', ') : '',
          credit : v.credit ? round(v.credit, 2) : '',
          debitContactCode : v.debit ? v.docs.map(d => d.contact.code).join(', ') : '',
          debitContactName : v.debit ? v.docs.map(d => d.contact.name).join(', ') : '',
          debit : v.debit ? round(v.debit, 2) : '',
          balance : v.balance ? round(v.balance, 2) : '',
        }
      })

      const data = [{
        sheet: this.sheetname,
        columns: this.columns,
        content
      }]

      return data
    }
  },
  methods: {
    download () {
      const settings = {
        fileName: this.filename,
      }

      xlsx(this.rows, settings)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
