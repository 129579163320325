import gql from 'graphql-tag'


export const LIST_STATEMENT = (templateType) => gql`query LIST_STATEMENT ($bankStatementType: String!, $startDate: Date!, $endDate: Date!, $q: FilterInput) {
  statements: listBankStatement${templateType} (bankStatementType: $bankStatementType, startDate: $startDate, endDate: $endDate, q: $q) {
    id type order transactionAt description
    debit credit balance remark
    docs {
      id type code name
      contactId contact {id type code name branch}
    }
  }
}`

export const UPDATE_STATEMENT_REMARK = (templateType) => gql`mutation UPDATE_STATEMENT_REMARK ($bankStatementType: String!, $bankStatementId: Int!, $input: BankStatement${templateType}Input!) {
  updateBankStatement: updateBankStatement${templateType}Remark (bankStatementType: $bankStatementType, bankStatementId: $bankStatementId, input: $input) {
    id type order transactionAt description
    debit credit balance remark
    docs {
      id type code name
      contactId contact {id type code name branch}
    }
  }
}`

export const LIST_STATEMENT_RPC = (templateType) => gql`query LIST_STATEMENT_RPC ($bankStatementType: String!, $uid: String!, $startDate: Date!, $endDate: Date!) {
  statements: listBankStatement${templateType}Rpc (bankStatementType: $bankStatementType, uid: $uid, startDate: $startDate, endDate: $endDate)
}`

export const WATCH_STATEMENT_MESSAGE = (templateType) => gql`subscription WATCH_STATEMENT_MESSAGE ($bankStatementType: String!, $uid: String!) {
  message: watchBankStatement${templateType}RpcMessage (bankStatementType: $bankStatementType, uid: $uid) {
    uid type message updatedAt
    data {
      type transactionAt
      description
      debit credit balance
    }
  }
}`

export const LIST_DROPDOWN_DOC = (templateType) => gql`query LIST_DROPDOWN_DOC ($bankStatementType: String!, $q: FilterInput) {
  items: listBankStatement${templateType}DropdownDoc (bankStatementType: $bankStatementType, q: $q) {
    id type code name
    contactId contact {id type code name branch}
  }
}`

export const CREATE_DOC = (templateType) => gql`mutation CREATE_DOC ($bankStatementType: String!, $bankStatementId: Int!, $docId: Int!) {
  createDoc: createBankStatement${templateType}Doc (bankStatementType: $bankStatementType, bankStatementId: $bankStatementId, docId: $docId) {
    id bankStatementId docId
  }
}`

export const DESTROY_DOC = (templateType) => gql`mutation DESTROY_DOC ($bankStatementType: String!, $bankStatementId: Int!, $docId: Int!) {
  destroyDoc: destroyBankStatement${templateType}Doc (bankStatementType: $bankStatementType, bankStatementId: $bankStatementId, docId: $docId) {
    id bankStatementId docId
  }
}`
