<template>
  <div class="row">
    <div class="col-12 col-md-4 text-center text-success">เดบิต: {{debitTotal | comma}}</div>
    <div class="col-12 col-md-4 text-center text-danger">เครดิต: {{creditTotal | comma}}</div>
    <div class="col-12 col-md-4 text-center ">รวม: {{overallTotal | comma}}</div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    debitTotal () {
      return this.items.reduce((t,v) => t += Math.abs(v.debit), 0)
    },
    creditTotal () {
      return this.items.reduce((t,v) => t += Math.abs(v.credit), 0)
    },
    overallTotal () {
      return this.items.reduce((t,v) => t += (Math.abs(v.debit) - Math.abs(v.credit)), 0)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
