<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="">
      <div class="form-row">
        <sgv-input-date
          class="col-6 col-md-4"
          v-model="startDate"
          label="เริ่ม"
          :validations="[
            {text: 'required!', value: $v.startDate.$dirty && !$v.startDate.required},
            {text: 'วันที่มากกว่าวันนี้', value: $v.startDate.$dirty && !$v.startDate.dateValid}
          ]">
        </sgv-input-date>

        <sgv-input-date
          class="col-6 col-md-4"
          v-model="endDate"
          label="ถึง"
          :validations="[
            {text: 'required!', value: $v.endDate.$dirty && !$v.endDate.required},
            {text: 'วันที่มากกว่าวันนี้', value: $v.endDate.$dirty && !$v.endDate.dateValid},
            {text: 'น้อยกว่าวันเริ่มต้น', value: $v.endDate.$dirty && !$v.endDate.dateGreater}
          ]">
        </sgv-input-date>
      </div>

      <div class="form-row">
        <sgv-input-text
          class="col-6 col-md-4"
          v-model="remark"
          label="หมายเหตุ">
        </sgv-input-text>

        <sgv-input-text
          class="col-6 col-md-4"
          v-model="amount"
          label="จำนวนเงิน">
        </sgv-input-text>
      </div>

      <div class="mb-3">
        <button class="btn btn-warning mr-2" @click="fetchRpc">
          Online
        </button>

        <button class="btn btn-primary mr-2" @click="fetchData">
          ฐานข้อมูล
        </button>

        <ListXlsx
          class="float-right"
          v-if="items.length > 0"
          :items="items"
          :startDate="startDate"
          :endDate="endDate"
          :bankStatementType="bankStatementType">
        </ListXlsx>
      </div>

      <div class="mb-3">
        สถานะ RPC: {{rpcMessage}}
      </div>

      <div class="mb-3 text-danger" v-if="rpcError">
        Error: {{rpcError}}
      </div>

      <ListSummary
        v-if="items.length > 0"
        class="mb-2"
        :items="items">
      </ListSummary>

      <div class="table-responsive" v-if="!isLoading">
        <table class="table">
          <thead>
            <tr>
              <th>วันที่</th>
              <th>รายละเอียด</th>
              <th>เดบิต</th>
              <th>เครดิต</th>
              <th>คงเหลือ</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in items">
              <tr :key="i" @dblclick="toggle(item.id)" class="pointer">
                <td style="white-space: nowrap;">
                  {{item.transactionAt | datetime}}

                  <div v-for="doc in item.docs" :key="doc.id">
                    <router-link
                      :to="toDoc(doc)"
                      class="text-decoration-none text-info">
                      <small style="white-space: nowrap;">
                        {{doc.code}}
                      </small>
                    </router-link>
                  </div>
                </td>
                <td>
                  {{item.description}}
                  <div v-if="item.remark" class="pre-line">
                    <small class="text-primary">{{item.remark}}</small>
                  </div>
                </td>
                <td class="text-success">
                  <span v-if="item.debit != 0">
                    {{item.debit | comma}}
                  </span>
                </td>
                <td class="text-danger">
                  <span v-if="item.credit != 0">
                    {{item.credit | comma}}
                  </span></td>
                <td>{{item.balance | comma}}</td>
              </tr>

              <tr :key="'sub' + i" v-if="selected && selected === item.id">
                <td colspan="5">
                  <ListFormDoc
                    :templateType="templateType"
                    :bankStatementType="bankStatementType"
                    :bankStatementId="item.id"
                    :formData="item">
                  </ListFormDoc>

                  <div class="form-row">
                    <sgv-input-textarea
                      class="col-12"
                      label="หมายเหตุ"
                      placeholder="หมายเหตุ..."
                      v-model="formData.remark">
                    </sgv-input-textarea>
                  </div>
                  <button
                    class="btn btn-warning mb-3 mr-2"
                    @click="updateData">
                    อัพเดท
                  </button>

                  <button
                    class="btn btn-primary mb-3"
                    @click="selected = null">
                    ยกเลิก
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div v-else>
        กำลังโหลดข้อมูล...
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import ListSummary from './ListSummary.vue'
import ListFormDoc from './ListFormDoc.vue'
import ListXlsx from './ListXlsx.vue'
import {
  WATCH_STATEMENT_MESSAGE,
  LIST_STATEMENT,
  UPDATE_STATEMENT_REMARK,
  LIST_STATEMENT_RPC,
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    bankStatementType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `BankStatement${this.$form.capitalize(this.bankStatementType)}List`,
      rFields: ['startDate', 'endDate', 'remark'],
      items: [],
      selected: null,
      startDate: '',
      endDate: '',
      remark: '',
      amount: '',
      isLoading: false,
      formData: {
        remark: ''
      },
      rpcUid: '',
      rpcMessage: '',
      rpcError: '',
      rpcMessageTime: new Date()
    }
  },
  validations: {
    startDate: {
      required,
      dateValid (value) {
        return value <= this.$date.current().date
      }
    },
    endDate: {
      required,
      dateValid (value) {
        return value <= this.$date.current().date
      },
      dateGreater (value) {
        return value >= this.startDate && value <= this.$date.current().date
      }
    }
  },
  apollo: {
    $subscribe: {
      message: {
        query () {
          return WATCH_STATEMENT_MESSAGE(this.templateType)
        },
        variables () {
          return {
            bankStatementType: this.bankStatementType,
            uid: this.rpcUid
          }
        },
        result (res) {
          const msg = res.data.message

          if (!this.rpcMessage || (msg.type === 'message' && msg.updatedAt > this.rpcMessageTime)) {
            this.rpcMessage = msg.message
            this.rpcMessageTime = msg.updatedAt
          } else if (msg.type === 'error') {
            this.rpcError = msg.message
            this.rpcMessageTime = msg.updatedAt
          } else if (msg.type === 'done') {
            this.items = msg.data
            this.rpcMessage = msg.message
          }
        },
        skip () {
          return !this.rpcUid
        }
      },
    },
  },
  methods: {
    toggle (id) {
      if (id && id !== this.selected) {
        this.selected = id
        this.formData.remark = this.items.find(x => x.id === id)?.remark || ''
      } else {
        this.selected = null
      }
    },
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid || this.isLoading) return

      this.setRetaining()
      this.rpcMessage = ''
      this.rpcError = ''
      this.isLoading = true
      this.$apollo.query({
        query: LIST_STATEMENT(this.templateType),
        variables: {
          bankStatementType: this.bankStatementType,
          startDate: this.$date.format(this.startDate).date,
          endDate: this.$date.format(this.endDate).date,
          q: {
            params: {
              search: this.remark,
              amount: this.amount
            }
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {this.items = res.data.statements})
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoading = false})
    },
    fetchRpc () {
      this.$v.$touch()
      if (this.$v.$invalid || this.isLoading) return

      const uid = this.$form.uid()
      this.setRetaining()
      this.rpcUid = uid
      this.rpcMessage = ''
      this.rpcError = ''

      this.items = []

      this.$apollo.query({
        query: LIST_STATEMENT_RPC(this.templateType),
        variables: {
          bankStatementType: this.bankStatementType,
          uid,
          startDate: this.$date.format(this.startDate).date,
          endDate: this.$date.format(this.endDate).date
        },
        fetchPolicy: 'no-cache'
      })
      .then(() => {
        this.rpcUid = uid
      })
      .catch(err => {
        this.rpcUid = null
        this.$toasted.global.error(err)
      })
    },
    updateData () {
      if (!this.selected) return

      this.$apollo.mutate({
        mutation: UPDATE_STATEMENT_REMARK(this.templateType),
        variables: {
          bankStatementType: this.bankStatementType,
          bankStatementId: this.selected,
          input: this.formData
        }
      })
      .then(res => {
        const data = res.data.updateBankStatement
        const item = this.items.find(v => v.id === data.id)
        item.remark = data.remark
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.selected = null
      })
      .catch(this.$toasted.global.error)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ListSummary,
    ListFormDoc,
    ListXlsx
  }
}
</script>

<style lang="css" scoped>
</style>
